import { SelectGroupContainer } from "../style";

export const SelectGroup = ({
  onAllUsersCheckboxHandler,
  addToSenlerGroups,
  addToVkGroups,
  senlerExcemptedUsersCheckbox,
  senlerNotFinishedUsersCheckbox,
  senlerTempoExcemptionUsersCheckbox,
  senlerBlackListCheckbox,
}) => {
  return (
    <SelectGroupContainer>
      <div>
        <h4>Группы Сенлера</h4>
        <p>
          <input
            onChange={(e) => onAllUsersCheckboxHandler(e)}
            type="checkbox"
            name="senlerAll"
            defaultChecked
          />
          все участники
        </p>
        {/* <p>
          <input
            onChange={(e) => addToSenlerGroups(e)}
            type="checkbox"
            name="senlerExcemptedUsers"
            // defaultChecked
            checked={senlerExcemptedUsersCheckbox}
          />
          исключение по кодовому слову
        </p> */}
        {/* <p>
          <input
            onChange={(e) => addToSenlerGroups(e)}
            type="checkbox"
            name="senlerNotFinishedUsers"
            // defaultChecked
            checked={senlerNotFinishedUsersCheckbox}
          />
          проходят но не закончили
        </p> */}
        <p>
          <input
            onChange={(e) => addToSenlerGroups(e)}
            type="checkbox"
            name="senlerTempoExcemption"
            // defaultChecked
            checked={senlerTempoExcemptionUsersCheckbox}
          />
          временное исключение
        </p>
        <p>
          <input
            onChange={(e) => addToSenlerGroups(e)}
            type="checkbox"
            name="senlerBlackList"
            // defaultChecked
            checked={senlerBlackListCheckbox}
          />
          ЧС
        </p>
      </div>
      <div>
        <h4>Сообщества ВК</h4>
        {/* <p>
          <input
            onChange={(e) => addToVkGroups(e)}
            type="checkbox"
            name="nt1"
          />
          НТС
        </p> */}
        <p>
          <input onChange={(e) => addToVkGroups(e)} type="checkbox" name="d1" />
          ДАНС
        </p>
        <p>
          <input
            onChange={(e) => addToVkGroups(e)}
            type="checkbox"
            name="nt2"
          />
          АНС
        </p>
        <p>
          <input onChange={(e) => addToVkGroups(e)} type="checkbox" name="d2" />
          ДПЛВ
        </p>
        <p>
          <input
            onChange={(e) => addToVkGroups(e)}
            type="checkbox"
            name="club"
          />
          Клуб
        </p>
        <p>
          <input
            onChange={(e) => addToVkGroups(e)}
            type="checkbox"
            name="osoznanost"
          />
          Осознанность
        </p>
        <p>
          <input
            onChange={(e) => addToVkGroups(e)}
            type="checkbox"
            name="osoznanostSpb"
          />
          Осознанные Сновидения школа СПб
        </p>
      </div>
    </SelectGroupContainer>
  );
};
