import { createContext } from "react";
import { useUsers } from "./state/user.state";
// import { useFlightData } from "./state/flight.state";

export const StoreContext = createContext();

export const StoreProvider = ({ context, children }) => {
  const useCreateStore = () => {
    const store = {
      user: useUsers(),
      // checklist: useChecklist(),
    };
    return store;
  };
  const store = useCreateStore();
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};
