import { createGlobalStyle } from "styled-components";

export const Global = createGlobalStyle`
:root{
    --mainFont: 'Open Sans', sans-serif;
    --white: #fff;
    --black: #1e1e1e;
    --p1: 14px;
    --h1: 20px;

}
    *{
                margin: 0;
                padding: 0;
                box-sizing: border-box;
                text-decoration: none;
    }

    body{
        h1,p,textarea{
            font-family: var(--mainFont);
        }
        h1{
            font-size: 30px;
        }
        p{
            font-size: 12px;

        }
    }

`;
