import bridge from "@vkontakte/vk-bridge";
import { useEffect, useState } from "react";
import { AppWrapper } from "./styles/UiKit";
import { AdminPage } from "./views/AdminPage";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuth, setIsAuth] = useState(false);

  const approvedAdmins = [
    "38259547",
    "99075695",
    "56871064",
    "416672868",
    "2003432",
  ];

  useEffect(() => {
    bridge.subscribe(({ detail: { type, data } }) => {
      if (type === "VKWebAppUpdateConfig") {
        const schemeAttribute = document.createAttribute("scheme");
        // schemeAttribute.value = data.scheme ? data.scheme : "client_light";
        schemeAttribute.value = "client_light";
        document.body.attributes.setNamedItem(schemeAttribute);
      }
    });
    async function fetchData() {
      window.VK.init({
        apiId: 51941038,
      });
      // window.VK.Auth.login(null, window.VK.access.FRIENDS);
      window.VK.Auth.login(
        (data) => {
          console.log("this is data: ", data);
          if (approvedAdmins.includes(data?.session?.mid)) {
            setIsAuth(true);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setIsAuth(false);
          }
        },
        // window.VK.access.FRIENDS
        window.VK.access.EMAIL
      );
      console.log(window.VK);

      // const user = await bridge.send("VKWebAppGetUserInfo");
      // console.log(user);
    }

    fetchData();
  }, []);

  // const { user } = useContext(StoreContext);
  return (
    <AppWrapper>
      {isLoading ? (
        <p>Loading...</p>
      ) : isAuth ? (
        <AdminPage />
      ) : (
        <p>You are not authorized</p>
      )}
      {/* <AdminPage /> */}
    </AppWrapper>
  );
}

export default App;
