import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AppWrapper = styled(Col)`
  height: 100vh;
  width: 100vw;
`;

export const Button = styled.button`
  justify-content: center;
  align-items: center;
  display: flex;
  border: none;
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 14px;
  padding: 10px 12px;
  font-weight: bold;
  :active {
    transform: scale(95%);
    filter: brightness(80%);
  }
`;
