import React from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "styled-components";
import { Global } from "./styles/globalStyles";
import { theme } from "./styles/theme";
import bridge from "@vkontakte/vk-bridge";
import { StoreProvider } from "./store/Store";
import { createRoot } from "react-dom/client";

const root = createRoot(document.getElementById("root"));
// bridge.send("VKWebAppInit", {
//   apiId: 51442657,
// });

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Global />
      <StoreProvider>
        <App />
      </StoreProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
