import styled from "styled-components";
import { Button, Col, Row } from "../../styles/UiKit";

export const AdminWrapper = styled(Col)`
  margin: 0 auto;
  height: 100%;
  width: 40%;
  h1 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  select {
    margin-bottom: 20px;
    height: 40px;
    font-size: 16px;
  }
  .file_input {
    min-height: 40px;
    display: flex;
    align-items: center;
    span {
      display: block;
      text-align: center;
      width: 40%;
    }
    input {
      min-height: 30px;
      width: 60%;
      font-size: 14px;
    }
  }
  .alert,
  .success {
    text-align: center;
    font-size: 16px;
    color: #cc3333;
    font-weight: bold;
  }

  .success {
    color: #00cc66;
  }

  textarea {
    color: black;
    font-weight: bold;
    font-size: 14px;
    padding: 5px;
    ::placeholder {
      color: #b0b0b0;
    }
  }

  .button_block {
    display: flex;
    margin-top: 10px;
    justify-content: space-around;
  }

  @media ${(props) => props.theme.media.phone} {
    height: 100%;
    width: 100%;
  }
`;

export const CleanButton = styled(Button)`
  background: #cc3333;
  color: white;
`;

export const ConfirmButton = styled(Button)`
  background: #00cc66;
  color: white;
`;

export const CancelButton = styled(Button)`
  background: #cccccc;
  color: white;
`;

export const AddMessageButton = styled(Button)`
  background: #0099ff;
  color: white;
`;

export const SendMessageButton = styled(Button)`
  background: #00cc66;
  color: white;
`;

export const SelectGroupContainer = styled(Row)`
  width: 100%;
  height: 35%;
  /* height: 40%; */
  div {
    width: 50%;
    h4 {
      text-align: center;
      margin-bottom: 5px;
    }
    p {
      font-size: 14px;
    }
    input {
      margin-right: 5px;
      width: 20px;
      height: 20px;
    }
    :last-child {
      padding-left: 20px;
    }
  }

  @media ${(props) => props.theme.media.phone} {
    height: 35%;
  }
`;

export const Modal = styled(Col)`
  display: ${(props) => (props.visible ? "fixed" : "none")};
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
  box-shadow: 2px 2px 10px 10px rgba(0, 0, 0, 0.4);
  .modal-dialog {
    background-color: white;
    width: 30%;
    height: 17%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media ${(props) => props.theme.media.phone} {
      width: 80%;
    }
    .modal-btn_group {
      margin-top: 10px;
      display: flex;
      justify-content: space-around;
    }
    h1 {
      font-size: 18px;
      text-align: center;
    }
  }
`;
