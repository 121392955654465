import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { SelectGroup } from "./components/SelectGroup";
import {
  AddMessageButton,
  AdminWrapper,
  CancelButton,
  CleanButton,
  ConfirmButton,
  Modal,
  SendMessageButton,
  // SendMessageButton,
} from "./style";

export const AdminPage = () => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const [attachment, setAttachment] = useState("");
  const [hashTag, setHashTag] = useState("#вебинарвсубботу");
  const [text, setText] = useState("");
  const [alert, setAlert] = useState("");
  const [success, setSuccess] = useState("");
  const [lastMessage, setLastMessage] = useState("");
  const [senlerGroups, setSenlerGroups] = useState([
    "senlerAll",
    // "senlerExcemptedUsers",
    // "senlerNotFinishedUsers",
    "senlerTempoExcemption",
    "senlerBlackList",
  ]);
  // const [vkGroups, setVkGroups] = useState(["nt1", "d1", "nt2", "d2", "club"]);
  const [vkGroups, setVkGroups] = useState([]);
  const [senlerExcemptedUsersCheckbox, setSenlerExcemptedUsersCheckbox] =
    useState(true);
  const [senlerNotFinishedUsersCheckbox, setSenlerNotFinishedUsersCheckbox] =
    useState(true);
  const [
    senlerTempoExcemptionUsersCheckbox,
    setSenlerTempoExcemptionUsersCheckbox,
  ] = useState(true);
  const [senlerBlackListCheckbox, setSenlerBlackListCheckbox] = useState(true);

  const handlers = {
    // senlerExcemptedUsers: setSenlerExcemptedUsersCheckbox,
    // senlerNotFinishedUsers: setSenlerNotFinishedUsersCheckbox,
    senlerTempoExcemption: setSenlerTempoExcemptionUsersCheckbox,
    senlerBlackList: setSenlerBlackListCheckbox,
  };

  const addToSenlerGroups = (e) => {
    const checked = e.target.checked;
    const name = e.target.name;
    let fun = handlers[name];
    if (checked === true) {
      fun((prev) => !prev);
      setSenlerGroups((prev) => [...prev, e.target.name]);
    } else {
      fun((prev) => !prev);
      let index = senlerGroups.indexOf(e.target.name);
      if (index > -1) {
        senlerGroups.splice(index, 1);
      }
    }
  };

  const onAllUsersCheckboxHandler = (e) => {
    const checked = e.target.checked;
    if (checked === true) {
      setSenlerGroups([
        "senlerAll",
        // "senlerExcemptedUsers",
        // "senlerNotFinishedUsers",
        "senlerTempoExcemption",
        "senlerBlackList",
      ]);
      // setSenlerNotFinishedUsersCheckbox(true);
      // setSenlerExcemptedUsersCheckbox(true);
      setSenlerTempoExcemptionUsersCheckbox(true);
      setSenlerBlackListCheckbox(true);
    } else {
      setSenlerGroups([]);
      // setSenlerNotFinishedUsersCheckbox(false);
      // setSenlerExcemptedUsersCheckbox(false);
      setSenlerTempoExcemptionUsersCheckbox(false);
      setSenlerBlackListCheckbox(false);
    }
  };

  const addToVkGroups = (e) => {
    const checked = e.target.checked;
    if (checked === true) {
      setVkGroups((prev) => [...prev, e.target.name]);
    } else {
      let index = vkGroups.indexOf(e.target.name);
      if (index > -1) {
        vkGroups.splice(index, 1);
      }
    }
  };

  const sendToDb = useCallback(
    async (e) => {
      e.stopPropagation();
      setModalVisibility(false);
      if (vkGroups.length === 0 || senlerGroups.length === 0) {
        setAlert("Пожалуйста отметьте группы для отправки");
        return setTimeout(() => setAlert(""), 3000);
      }
      if (text === "") {
        setAlert("Пожалуйста введите текст сообщения");
        return setTimeout(() => setAlert(""), 3000);
      }
      if (text !== "") {
        console.log(senlerGroups);
        axios.post("https://ymailer.stn21f.ru/api/v1/messages", {
          text,
          hashTag,
          vkGroups: vkGroups,
          // vkGroups: ["tg1", "tg2", "tg3"],
          senlerGroups: senlerGroups,
          // senlerGroups: ["senlerAll"],
          attachment: attachment,
        });
        setText("");
        setSuccess("Сообщение добавлено в рассылку");
        return setTimeout(() => setSuccess(""), 3000);
      }
    },
    [hashTag, senlerGroups, text, vkGroups, attachment]
  );

  const cleanHandler = () => {
    // console.log(senlerGroups);
    // console.log(vkGroups);
    setText("");
  };

  const modalClose = (e) => {
    e.stopPropagation();
    setModalVisibility(false);
  };

  const testSendMessageHandler = async () => {
    const { data } = axios.post("https://ymailer.stn21f.ru/api/v1/test", {
      message: "test",
    });
    console.log(data);
    setSuccess("Сообщение отправлено");
    return setTimeout(() => setSuccess(""), 3000);
  };

  useEffect(() => {
    (async () => {
      let { data } = await axios.get(
        "https://ymailer.stn21f.ru/api/v1/messages/last"
      );
      let senlerGroups = data.senler_groups.map((senlerGroup) => {
        if (senlerGroup === "senlerAll") {
          return (senlerGroup = "все участники");
        }
        // if (senlerGroup === "senlerExcemptedUsers") {
        //   return (senlerGroup = "исключение по кодовому слову");
        // }
        // if (senlerGroup === "senlerNotFinishedUsers") {
        //   return (senlerGroup = "проходят но не закончили");
        // }
        if (senlerGroup === "senlerTempoExcemption") {
          return (senlerGroup = "временное исключение");
        }
        if (senlerGroup === "senlerBlackList") {
          return (senlerGroup = "ЧС");
        }
        return "";
      });
      let vkGroups = data.vk_groups.map((group) => {
        if (group === "nt1") {
          return (group = "НТС");
        }
        if (group === "d1") {
          return (group = "ДАНС");
        }
        if (group === "nt2") {
          return (group = "АНС");
        }
        if (group === "d2") {
          return (group = "ДПЛВ");
        }
        if (group === "club") {
          return (group = "Клуб");
        }
        if (group === "osoznanost") {
          return (group = "Осознанность днём и ночью");
        }
        if (group === "osoznanostSpb") {
          return (group = "Осознанные Сновидения Санкт-Петербургская школа");
        }
        return "";
      });
      setLastMessage(
        `Последнее сообщение: ${data.created_at + " (MSK)" ?? ""}\n\n` +
          data.hash_tag +
          "\n" +
          data.text +
          "\n\nСообщесва ВК: " +
          vkGroups +
          "\n\nГруппы Сенлера: " +
          senlerGroups
      );
    })();
  }, [sendToDb]);

  // console.log(vkGroups);

  return (
    <>
      <Modal visible={modalVisibility} onClick={modalClose}>
        <div className="modal-dialog">
          <h1>Добавить сообщение в рассылку?</h1>
          <div className="modal-btn_group">
            <ConfirmButton onClick={(e) => sendToDb(e)}>добавить</ConfirmButton>
            <CancelButton onClick={modalClose}>отменить</CancelButton>
          </div>
        </div>
      </Modal>
      <AdminWrapper>
        <h1>панель администратора</h1>
        <select
          name="Хэштэг"
          id=""
          onChange={(e) => setHashTag(e.target.value)}
        >
          <option value="#вебинарвсубботу">#вебинарвсубботу</option>
          <option value="#полезноенаютубе">#полезноенаютубе</option>
          <option value="#вебинар">#вебинар</option>
          <option value="#видеоЮтуб">#видеоЮтуб</option>
        </select>
        <SelectGroup
          addToSenlerGroups={addToSenlerGroups}
          addToVkGroups={addToVkGroups}
          onAllUsersCheckboxHandler={onAllUsersCheckboxHandler}
          // senlerExcemptedUsersCheckbox={senlerExcemptedUsersCheckbox}
          // senlerNotFinishedUsersCheckbox={senlerNotFinishedUsersCheckbox}
          senlerTempoExcemptionUsersCheckbox={
            senlerTempoExcemptionUsersCheckbox
          }
          senlerBlackListCheckbox={senlerBlackListCheckbox}
        />
        <div className="file_input">
          <span>Ссылка на вложение</span>
          <input type="text" onChange={(e) => setAttachment(e.target.value)} />
        </div>
        <p className="alert">{alert}</p>
        <p className="success">{success}</p>
        <textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          cols="30"
          rows="10"
          placeholder={lastMessage}
        ></textarea>
        <div className="button_block">
          <AddMessageButton onClick={() => setModalVisibility(true)}>
            добавить
          </AddMessageButton>
          <SendMessageButton onClick={testSendMessageHandler}>
            проверить сообщение
          </SendMessageButton>
          <CleanButton onClick={cleanHandler}>отчистить</CleanButton>
        </div>
      </AdminWrapper>
    </>
  );
};
